import { User } from '@/interfaces/user.interface';
import APIService from '@/services/API';
import HyperDX from '@hyperdx/browser';

import { loginFailure, loginRequest, loginSuccess, updateUser } from '../reducers/auth';

export const login = (email: string, password: string) => async (dispatch: any) => {
  try {
    dispatch(loginRequest());
    const response = await APIService.login({ email, password });
    if (response.ok) {
      if (response.data) {
        HyperDX.setGlobalAttributes({
          userId: response.data.user?._id as string,
          userEmail: response.data.user?.email as string,
        });
        dispatch(
          loginSuccess({
            token: response.data.accessToken,
            user: response.data.user as User,
            rol: response.data.authentication.payload.rol,
          }),
        );
      }
    } else {
      dispatch(
        loginFailure({
          error: response?.data?.message as string,
          errorCode: response?.data?.code as number,
        }),
      );
    }
  } catch (error: any) {
    dispatch(loginFailure({ error: error.message, errorCode: undefined }));
  }
};

export const setUser = (user: User) => async (dispatch: any) =>
  dispatch(updateUser(user));
